function hiddeNav() {
    let mh = document.querySelector("#menuHidden");
    let sml = document.querySelector("#showMenuLat");
    let ml = document.querySelectorAll(".mdl-layout");

    if (mh && sml && ml) {
        mh.classList.remove("is-visible");
        sml.classList.remove("is-visible");
        ml.forEach(el => el.setAttribute("style", "overflow-y: auto"))
    }
}